import { ajaxPromise } from "./promiseUtils";

/**
* Dynamically loads the javascript specified in the src parameter
* if it hasn't been already, appending it as a <script> tag to the body.
*
* @param src: The src attribute used for the <script> tag
* @param callback: Optional callback function executed when the script
*                  is loaded (but possibly not executed yet).
*/
export const loadScript = function (src, callback) {
    // If the script already exists, just run the callback if that exists
    if ($("script[src='" + src + "']").length > 0) {
        callback && callback();
        return;
    }

    var scriptElement = document.createElement("script");
    scriptElement.type = "text/javascript";
    scriptElement.src = src;
    scriptElement.async = false;
    scriptElement.onload = callback;
    document.body.appendChild(scriptElement);
};

/**
* Dynamically loads the specified css file and appends it to the head
* of the page, if it hasn't been loaded already.
*
* @param href: The location of the CSS file
*/
export const loadCss = function (href) {
    if ($("link[href='" + href + "']").length > 0) {
        return;
    }

    $('<link>')
        .attr('rel', 'stylesheet')
        .attr('type', 'text/css')
        .attr('href', href)
        .appendTo('head');
};

/**
* Starts a file download by inserting an iframe into the page with the url of the file to load
*
* @param url: URL of the file to download
*/
export const triggerFileDownload = function (url, errorHandler) {

    // If a previous download iframe exists, remove it to avoid bloating the DOM
    $("#file-download-iframe").remove();

    var iframe = $("<iframe />");
    iframe
        .on("load", event => {
            // Download shouldn't trigger load event, must be an error
            errorHandler(iframe.contents().find("body").text());
        })
        .attr({
            id: "file-download-iframe",
            src: url
        })
        .hide()
        .appendTo($("body"));
};

/**
* Starts a file download by inserting an iframe into the page and submiting a from to it
*
* @param url: URL of the file download endpoint
* @param fields: POST request form parameters
*/
export const triggerFileDownloadByPost = function (url, fields, errorHandler) {
    // If a previous download iframe exists, remove it to avoid bloating the DOM
    $("#file-download-iframe").remove();

    const form = $("<form method='post' target='file-download-iframe' />")
        .attr({
            action: url
        });
    for (const fieldName in fields) {
        const input = $("<input type='hidden' />")
            .attr({
                name: fieldName,
                value: fields[fieldName]
            });
        form.append(input);
    }

    let isInitialIframeLoad = true;
    const iframe = $("<iframe name='file-download-iframe' src='about:blank' />")
        .on("load", () => {
            if (isInitialIframeLoad) {
                isInitialIframeLoad = false;
                form.submit();
            }
            else if (errorHandler) {
                errorHandler(iframe.contents().find("body").text());
            }
        });

    $("<div id='file-download-iframe' style='display: none;'></div>")
        .append(form)
        .append(iframe)
        .appendTo($("body"));
};

/**
 * Openes a file upload dialog, and posts the selected file to the provided url
 * 
 * @param {string} url: URL to upload the file to
 * @param {Object} options: Options to configure the dialog
 * @param {boolean} options.allowMultiple: True if the file uploader should allow multiple files. Defaults to true
 * @param {number} options.maxSizeMB: Max filesize in MB allowed for the uploaded files. Defaults to 20 MB
 * 
 * @returns {Promise} Promise which resolves to the value returned by the server when the upload completes.
 * Resolves to null if the user does not select a valid file. Note that the promise may never resolve if
 * the user cancels the file upload dialog (sadly not easy to detect).
 */
export const triggerFileUpload = function (url, { allowMultiple = true, maxSizeMB = 20 } = {}) {
    let uploadResolver, rejectResolver;
    const uploadPromise = new Promise((resolve, reject) => {
        uploadResolver = resolve;
        rejectResolver = reject;
    });

    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = allowMultiple;
    input.onchange = async e => {
        if (!e.target.files?.length) {
            uploadResolver(null);
            return;
        }

        const files = [...e.target.files];
        const maxSizeBytes = maxSizeMB * 1024 * 1024;

        if (files.some(file => file.size > maxSizeBytes)) {
            alert(`Uploaded files cannot be larger than ${maxSizeMB}MB.`);
            uploadResolver(null);
            return;
        }

        try {
            uploadResolver(await uploadFiles(url, files));
        } catch (error) {
            rejectResolver(error);
        }
    }
    input.click();

    return uploadPromise;
};

const uploadFiles = async function (url, files) {
    let formData = new FormData();
    files.forEach(file => {
        formData.append("file", file);
    });

    return await ajaxPromise({
        url,
        type: "POST",
        data: formData,
        processData: false,
        contentType: false,
        dataType: undefined
    });
};