import { globalOptions } from "../../settings";

export type Canceler = {
    cancel: () => void
}

/**
 * Global wrapper around a jquery ajax request that converts it to an es6 style promise
 * @param {} ajaxSettings
 * @param {bool} canUseRegionalServer - if true, attempts to make request to regional server
 * @param {} canceler - promise body sets canceler.cancel to a function which kills the ajax request
 * @returns {Promise} 
 */
export const ajaxPromise = function<T> (ajaxSettings, canUseRegionalServer?: boolean, canceler?: Canceler) {
    const xhrFields = { withCredentials: false };

    if (canUseRegionalServer && globalOptions.regionalServerUrl) {
        let regionalServerUrl = globalOptions.regionalServerUrl;
        regionalServerUrl = regionalServerUrl.endsWith('/') ? regionalServerUrl.slice(0, regionalServerUrl.length - 1) : regionalServerUrl;
        const standardUrl = ajaxSettings.url.startsWith('/') ? ajaxSettings.url.slice(1) : ajaxSettings.url;
        ajaxSettings.url = regionalServerUrl + '/' + standardUrl;
        xhrFields.withCredentials = true;
    }

    return new Promise<T>((resolve, reject) => {

        const defaults = {
            type: "GET",
            dataType: "json"
        };

        const successResolve = (data) => {
            resolve(data);
            //Chain out if needed
            if (ajaxSettings.success)
                ajaxSettings.success(data);
        };

        const errorResolve = (jqXHR, textStatus, errorThrown) => {
            reject(jqXHR.responseText || errorThrown);
            //Chain out if needed
            if (ajaxSettings.error)
                ajaxSettings.error(jqXHR, textStatus, errorThrown);
        };

        const newSettings = _.extend(defaults, ajaxSettings, { xhrFields: xhrFields, success: successResolve, error: errorResolve });
        const xhr = $.ajax(newSettings);

        if (canceler)
            canceler.cancel = () => xhr.abort();
    });
};

/**
 * Adds a timeout to a promise, rejecting it if the timeout passes
 * @param {number} ms - the timeout, in milliseconds, to wait before rejecting
 * @param {Promise} promise - the promise to attach a timeout to
 * @param {string} timeoutMsg - an optional error that is returned when timeout occurs
 * @returns {Promise} a race promise with the timeout 
 */
export const timeoutPromise = function (ms, promise, timeoutMsg) {
    let successPassed = false;
    const successPromise = promise.then((succObj) => {
        successPassed = true;
        return succObj;
    }, (rejObj) => rejObj);

    const timeout = new Promise((resolve, reject) => {
        const id = setTimeout(() => {
            clearTimeout(id);
            if (!successPassed)
                reject([timeoutMsg || "Promise timed out"]);
        }, ms);
    });

    return Promise.race([
        successPromise,
        timeout
    ]);
};

/**
 * Adds custom headers to HTTP Requests
 * 
 * PRIORITY FOR IDENTIFIERS (generally): imosListID > formName > rootDataFormID
 * 
 * @param {companyCode} str - string representing company code
 * @param {identifier} str - string representing identifier. Typically imosListID or some other form of module ID
 * @returns {headers} JSON object that contains standard custom headers
 */
export const createHTTPCustomHeaders = function (moduleID, moduleType) {
    let companyCode = globalOptions.currentCompanyCode;
    return {
        "X-VesonCustomHeader-CompanyCode": companyCode,
        "X-VesonCustomHeader-ModuleID": moduleID,
        "X-VesonCustomHeader-ModuleType": moduleType
    }
};

/**
 * Returns a promise making a request to requestURL which starts a long-running imosapp process,
 * then polling resultURLGetter(resourceID) for the result. Promise will poll every pollIntervalSeconds
 * (default 10) up to a limit of pollLimit times (default 12);
 */
export const longRunningRequestPromise = function (requestURL, requestParams, resultURLGetter, pollIntervalSeconds, pollLimit) {
    if (!pollIntervalSeconds)
        pollIntervalSeconds = 10;
    if (!pollLimit)
        pollLimit = 12;

    return new Promise(function (resolve, reject) {
        $.ajax({
            contentType: "application/json",
            data: requestParams || {},
            type: "GET",
            url: requestURL
        }).done(function (response) {
            var getResource = function (resourceID, currentTries) {
                $.ajax({
                    contentType: "application/json",
                    type: "GET",
                    url: resultURLGetter(resourceID)
                }).done(function (resultResponse) {
                    if (resultResponse.complete)
                        resolve(resultResponse);
                    else if (currentTries > pollLimit)
                        reject();
                    else
                        setTimeout(function () { getResource(resourceID, currentTries + 1) }, pollIntervalSeconds * 1000);
                }).fail(function () {
                    reject();
                });
            };
            getResource(response, 0);
        }).fail(function () {
            reject();
        });
    });
};