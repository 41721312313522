var configFlagMinSchemaVersions = {
    "CFGEnableDefaultViaList": 249,
    "CFGEnableItinRoutingByLeg": 249,
    "CFGEnableBerthMgmtActivities": 330,
    "CFGAddChildCargoCOAStructure": 332
};

var configFlagCustomTruthyValues = {
    "CFGLaytimeCalculator": "laytime_c.exe",
};

var currentSchemaVersion = 0;

let _configFlags, _enabledPermissions, _readOnlyPermissions, _enabledLicenses;

var satisfiesSchemaVersion = function(id) {
    var flagMinSchemaVersion = configFlagMinSchemaVersions[id] || 0;
    return currentSchemaVersion >= flagMinSchemaVersion;
};

// High level settings object for values which may need to be accessed anywhere.
// Set before the shell is initialized.
export let globalOptions = {};

export const setGlobalOptions = function (options) {
    globalOptions = options;
};

export const setSchemaVersion = function (version) {
    currentSchemaVersion = version;
};

export const getSchemaVersion = function () {
    return currentSchemaVersion;
};

export const setConfigFlags = function(configFlags) {
    _configFlags = configFlags || {};
};

/**
 * Sets enabled permissions. Make sure there are still server side checks for these permissions
 */
export const setEnabledPermissions = function (enabledPermissions) {
    _enabledPermissions = enabledPermissions || [];
};

export const setReadOnlyPermissions = function (readOnlyPermissions) {
    _readOnlyPermissions = readOnlyPermissions || [];
};

/**
 * Sets enabled Licenses. Make sure there are still server side checks for these licenses
 */
export const setEnabledLicenses = function (enabledLicenses) {
    _enabledLicenses = enabledLicenses || [];
};

/**
 * checking for licenses
 */
export const getHasLicense = function (license, enabledLicenses) {
    if (enabledLicenses === undefined) enabledLicenses = _enabledLicenses;

    // check if we have they "everything" license
    if (_.some(enabledLicenses, enabledLicense => enabledLicense.id === "*")) {
        return true;
    }

    // also possible we have the "all imos modules" license
    if (license.indexOf("imos.") === 0 && _.some(enabledLicenses, enabledLicense => enabledLicense.id === "imos.*")) {
        return true;
    }
    return _.some(enabledLicenses, enabledLicense => enabledLicense.id === license);
};

const getConfigFlags = function () {
    return _configFlags || {} ;
};

/**
 * For use by automation or enterprising users
 */
export const setConfigFlag = function (key, val) {
    var configFlags = _configFlags || {};
    configFlags[key] = val;
    _configFlags = configFlags;
};

/**
 * Gets if a permission is enabled for the current user
 */
export const getHasPermission = function(permission) {
    if (!_enabledPermissions)
        return false;

    return !!(_.find(_enabledPermissions, function (p) {
        return p === permission;
    })); //Result forced to boolean
};

export const getHasReadOnlyPermission = function (permission) {
    if (!_readOnlyPermissions)
        return false;

    return !!(_.find(_readOnlyPermissions, function (p) {
        return p === permission;
    }));
};

export const getConfigFlagById = function (id, defaultValue, minSchemaValue) {
    var flags = getConfigFlags();

    // use this value if the database schema is lower than the config flag requirement schema.
    // config flag min schema is a dictionary in shellModel.
    if (!satisfiesSchemaVersion(id)) {
        return minSchemaValue;
    }

    // use this value if the flag doesn't exists. It happens when the imos master config doesn't have the flag yet,
    // which is possible when the imos / veslink versions don't match.
    // If versions match, but schema is lower, flags will contain the key and value.
    if (!flags.hasOwnProperty(id)) {
        return defaultValue;
    }

    return flags[id];
};

export const getBoolConfigFlagById = function (id, defaultValue, minSchemaValue) {
    var targetFlag = getConfigFlagById(id, !!defaultValue, !!minSchemaValue);
    var customTruthyValue = configFlagCustomTruthyValues[id];

    if (customTruthyValue != null) {
        return targetFlag && targetFlag === customTruthyValue;
    }
    return targetFlag && (targetFlag === "Y" || targetFlag === "y" || targetFlag === "1" || targetFlag === true);
};